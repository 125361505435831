import APIClient from './api-client.service';
import { uploadDocument } from './image.service';

export const getProperties = () => {
  const userId = localStorage.getItem('uid');
  return APIClient.getFromServer(`/property/?owner=${userId}&limit=50`);
};

export const createProperty = async (payload: Record<string, any>) => {
  const property: any = await APIClient.postToServer('/property/', payload);
  if (payload.imageFiles && property?.data?.id) {
    await Promise.all(
      payload.imageFiles.map((e: any) => uploadDocument(property.data.id, e)),
    );
  }
  return property;
};

export const updateProperty = async (
  id: number,
  payload: Record<string, any>,
) => {
  if (payload.imageFiles) {
    await Promise.all(
      payload.imageFiles.map((e: any) => uploadDocument(id, e)),
    );
  }
  return APIClient.putToServer(`/property/${id}/`, payload);
};

export const deleteProperty = (id: number) => {
  return APIClient.deleteFromServer(`/property/${id}/`);
};

export const deletePropertyDocument = (id: number) => {
  return APIClient.deleteFromServer(`/property/${id}/`);
};
