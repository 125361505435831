import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import { Button } from '../../../components/button.component';
import { CheckBoxInput } from '../../../components/check-box.component';
import { Dropdown } from '../../../components/dropdown.component';
import { TabView } from '../../../components/tab-view.component';
import { TextAreaInput } from '../../../components/text-area.component';
import { createInquiry } from '../../../services/inquiry.action';
import { getProperties } from '../../../services/property.action';
import {
  getCounty,
  getMineralTypes,
  getServicesOffered,
  getState,
} from '../../../services/statics.action';

const BuyGrowPortfolio: React.FC = () => {
  const classes = styles();
  const [data, setData] = useState({
    lookingFor: 'Purchase',
  } as any);
  const [minerals, setMinerals] = useState([] as any[]);
  const [state, setState] = useState([] as any[]);
  const [county, setCounty] = useState([] as any[]);
  const [errors, setErrors] = useState({} as any);

  const inputHandler = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getCountyList = async (stateId: string) => {
    const res = await getCounty(stateId);
    return res.data.results.map((e: any) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
  };

  const onSubmit = () => {
    const errors: any = {};

    if (!data.minerals) errors.minerals = true;
    if (!data.state) errors.state = true;
    if (!data.county) errors.county = true;
    if (!data.comment) errors.comment = true;

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error('Please check marked fields and correct your entries.');
      return;
    }

    const payload: any = {
      category: 'Buy / Grow Portfolio',
      minerals_of_interest: data.minerals.map((e: any) => e.value),
      looking_for: data.lookingFor,
      looking_state: data.state.value,
      looking_county: data.county.value,
      comment: data.comment,
    };

    createInquiry(payload).then((res) => {
      if (res.status === 201) {
        toast.success('Inquiry submitted.');
      } else {
        toast.error('Error encountered submitting request.');
      }
    });
  };

  useEffect(() => {
    getState().then((res: any) => {
      setState(
        res.data.results.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      );
    });
    getMineralTypes().then((res: any) => {
      setMinerals(
        res.data.results.map((e: any) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      );
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            Looking for
          </span>
          <CheckBoxInput
            checked={data.lookingFor === 'Purchase'}
            onChange={() => inputHandler('lookingFor', 'Purchase')}
            inputStyle={classes.checkbox}
            text="Purchase"
          />
          <CheckBoxInput
            checked={data.lookingFor === 'Lease'}
            onChange={() => inputHandler('lookingFor', 'Lease')}
            inputStyle={classes.checkbox}
            text="Lease"
          />
          <CheckBoxInput
            checked={data.lookingFor === 'Not Sure'}
            onChange={() => inputHandler('lookingFor', 'Not Sure')}
            inputStyle={classes.checkbox}
            text="Not Sure"
          />
        </div>
        <div style={{ marginTop: 12, maxWidth: 412 }}>
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            Mineral of interest
          </span>
          <Dropdown
            options={minerals}
            selectedOption={data.minerals}
            onChange={(value: any) => inputHandler('minerals', value)}
            placeholder="Mineral Type"
            error={errors.minerals}
            isMulti={true}
          />
        </div>
        <div style={{ marginTop: 12, maxWidth: 412 }}>
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            Where are you looking?
          </span>
          <Dropdown
            options={state}
            selectedOption={data.state}
            onChange={(value: any) => {
              setData({
                ...data,
                state: value,
                county: null,
              });
              getCountyList(value.value).then((data) => setCounty(data));
            }}
            placeholder="State"
            error={errors.state}
          />
        </div>
        <div style={{ marginTop: 12, maxWidth: 412 }}>
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            County
          </span>
          <Dropdown
            options={county}
            selectedOption={data.county}
            onChange={(value: any) => inputHandler('county', value)}
            placeholder="County"
            error={errors.county}
          />
        </div>
        <div style={{ marginTop: 12 }}>
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            How can we help you?
          </span>
          <TextAreaInput
            placeholder="Enter your comments"
            inputStyle={classes.fieldInput}
            value={data.comment}
            onTextChange={(value: string) => inputHandler('comment', value)}
            error={errors.comment}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            text="Submit"
            buttonStyle={classes.button}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </>
  );
};

const Sell: React.FC = () => {
  const classes = styles();
  const [properties, setProperties] = useState([] as any[]);

  useEffect(() => {
    // Get Properties
    getProperties().then((res: any) => {
      setProperties(
        res.data?.results?.map((e: any) => ({
          id: e.id,
          name: e.alias,
          selected: false,
        })),
      );
    });
  }, []);

  const onSubmit = () => {
    if (properties.filter((e) => e.selected).length < 1) {
      toast.error('No property selected.');
      return;
    }

    const payload: any = {
      category: 'Sell',
      properties: properties.filter((e) => e.selected).map((e) => e.id),
      // for other tab
    };

    createInquiry(payload).then((res) => {
      if (res.status === 201) {
        toast.success('Inquiry submitted.');
      } else {
        toast.error('Error encountered submitting request.');
      }
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {properties.length > 0 && (
          <div style={{ marginBottom: 12 }}>
            <span className={`${classes.text} ${classes.fieldLabel}`}>
              Which property do you wish to sell?
            </span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {properties.map((e) => (
                <CheckBoxInput
                  key={e.id}
                  checked={e.selected}
                  onChange={() => {
                    const idx = properties.findIndex((obj) => obj.id === e.id);
                    properties[idx].selected = !properties[idx].selected;
                    setProperties([...properties]);
                  }}
                  inputStyle={classes.checkbox}
                  text={e.name}
                />
              ))}
            </div>
          </div>
        )}
        <div style={{ marginTop: 20 }}>
          <Button
            text="Submit"
            buttonStyle={classes.button}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </>
  );
};

const GetConnectedWithService: React.FC = () => {
  const classes = styles();
  const [data, setData] = useState({} as any);
  const [services, setServices] = useState([] as any[]);

  useEffect(() => {
    getServicesOffered().then((res: any) => {
      setServices(
        res.data?.results?.map((e: any) => ({
          id: e.id,
          name: e.service,
          selected: false,
        })),
      );
    });
  }, []);

  const inputHandler = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const onSubmit = () => {
    if (services.filter((e) => e.selected).length < 1) {
      toast.error('No service selected.');
      return;
    }
    if (!data.comment) {
      toast.error('Enter comment.');
      return;
    }

    const payload: any = {
      category: 'Get Connected with Service',
      services: services.filter((e) => e.selected).map((e) => e.id),
      comment: data.comment,
    };

    createInquiry(payload).then((res) => {
      if (res.status === 201) {
        toast.success('Inquiry submitted.');
      } else {
        toast.error('Error encountered submitting request.');
      }
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ marginBottom: 12 }}>
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            What kind of service do you need help with today?
          </span>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              width: 500,
            }}
          >
            {services.map((e: any) => (
              <CheckBoxInput
                key={e.id}
                checked={e.selected}
                onChange={() => {
                  const idx = services.findIndex((obj: any) => obj.id === e.id);
                  services[idx].selected = !services[idx].selected;
                  setServices([...services]);
                }}
                inputStyle={classes.checkbox}
                text={e.name}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: 12 }}>
          <span className={`${classes.text} ${classes.fieldLabel}`}>
            Specify your needs
          </span>
          <TextAreaInput
            placeholder="Enter your comments"
            inputStyle={classes.fieldInput}
            value={data.comment}
            onTextChange={(value: string) => inputHandler('comment', value)}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <Button
            text="Submit"
            buttonStyle={classes.button}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </>
  );
};

export const ManageMyRightComponent: React.FC = () => {
  const classes = styles();
  const [activePage, setActivePage] = useState('buyGrowPortflio');

  const tabs = [
    {
      id: 'buyGrowPortflio',
      text: 'Buy / Grow Portfolio',
      element: <BuyGrowPortfolio />,
      onSelect: () => setActivePage('buyGrowPortflio'),
    },
    {
      id: 'sell',
      text: 'Sell',
      element: <Sell />,
      onSelect: () => setActivePage('sell'),
    },
    {
      id: 'getConnectedWithService',
      text: 'Get Connected With Service',
      element: <GetConnectedWithService />,
      onSelect: () => setActivePage('getConnectedWithService'),
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <span className={classes.header}>Manage My Rights</span>
      </div>
      <div style={{ width: '100%' }}>
        <TabView tabs={tabs} activePage={activePage} />
      </div>
    </div>
  );
};

const styles = createUseStyles({
  container: {
    margin: 30,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  headerContainer: {
    marginBottom: 25,
  },

  header: {
    fontSize: 30,
    fontWeight: '600',
    color: '#000',
  },

  text: {
    fontStyle: 'normal',
    textAlign: 'left',
  },

  fieldLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#344054',
    marginRight: 15,
    lineHeight: 2,
  },

  checkbox: {
    color: '#344054',
    minWidth: 'max-content',
    marginRight: 15,
    fontWeight: 500,
  },

  fieldInput: {
    width: 370,
  },

  button: {
    height: 44,
    width: 209,
  },

  sellField: {
    width: 62,
  },
});
