import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router';
import CheckIcon from '../../assets/check-icon.svg';
import { ImageCarousel } from '../../components/carousel.component';
import { Footer } from '../../components/footer.component';
import { Header } from '../../components/header.component';
import { AskAwayComponent } from './components/ask-away.component';
import { InquiryModal } from './components/inquiry-modal.component';
import { RegistrationComponent } from './components/registation.component';

interface CustomProps {
  navigation?: any;
}

const checkedText = [
  'Maximize Value',
  'Easier to manage heirship',
  'Avoid forced pooling',
  'Avoid lost revenue',
];

const carouselImages = [
  '/Home page 4.jpg',
  '/Home page 1.jpg',
  '/Home page 2.jpg',
  '/Home page 3.jpg',
];

export const HomePage: React.FC<CustomProps> = ({ navigation }) => {
  const classes = styles();
  const [inquiryModalVisible, setInquiryModalVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.homeImageContainer}>
        <div className={classes.carouselContainer}>
          <ImageCarousel
            images={carouselImages}
            containerClassStyle={classes.carousel}
          />
        </div>
      </div>

      <div style={{ marginLeft: 80, marginRight: 80 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 17,
          }}
        >
          <span className={classes.headText}>Register Now</span>
          <span className={classes.headText}>
            Sign up is free with no monthly cost
          </span>
        </div>

        <div className={classes.subheader}>
          {checkedText.map((e, i) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontSize: 22,
                fontWeight: 'normal',
                color: '#000',
                marginRight: 57,
              }}
              key={i}
            >
              <img
                src={CheckIcon}
                style={{ height: 24, width: 24, marginRight: 12 }}
              />
              <span>{e}</span>
            </div>
          ))}
        </div>

        <div className={classes.regaskaway}>
          <RegistrationComponent
            onLandOwnerClick={() => navigate('/land-owner/registration')}
            onServiceProviderClick={() =>
              navigate('/service-company/registration')
            }
          />

          <AskAwayComponent onInquiry={() => setInquiryModalVisible(true)} />
        </div>

        <InquiryModal
          visible={inquiryModalVisible}
          onClose={() => setInquiryModalVisible(false)}
        />

        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  homeImageContainer: {
    position: 'relative',
    height: 380,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  carouselContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  carousel: {
    height: 380,
    width: '99vw',
  },

  searchInput: {
    width: 538,
  },

  headText: {
    fontSize: 48,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: -0.96,
    textAlign: 'left',
    color: '#000000',
  },

  subheader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 23,
  },

  button: {
    width: 91,
    height: 40,
    marginLeft: 16,
  },

  regaskaway: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  footer: {
    marginTop: 149,
    borderTop: ['solid', 1, '#d0d5dd'],
  },
});
