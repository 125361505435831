import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import { Button } from '../../../components/button.component';
import { Pagination } from '../../../components/pagination.component';
import apiClientService from '../../../services/api-client.service';
import {
  addHeir,
  deleteHeir,
  getHeirs,
  updateHeir,
} from '../../../services/heir.action';
import { AddHeirModal } from './add-heir-modal.component';
import { HeirCardProps } from './heir-card.component';
import { PaginatedHeirCardComponent } from './paginated-heir-card.component';

export type HeirType = 'Previous' | 'Potential';

export type Heir = {
  id?: string;
  description: string;
  relationship: string;
  type: HeirType;
  firstname: string;
  lastname: string;
  deceased: boolean;
  diedInState: Record<string, any>;
  diedInCounty: Record<string, any>;
  diedInCity: Record<string, any>;
  probateCity: Record<string, any>;
  probateState: Record<string, any>;
  probateCounty: Record<string, any>;
  yearOfDeath: number;
  estateProbated: boolean;
  documents: string[];
};

export type HeirPotential = {
  description: string;
  type: HeirType;
  relationship: string;
  age: number;
  firstname: string;
  lastname: string;
  includedInWill: boolean;
  streetAddress: string;
  city: Record<string, any>;
  county: Record<string, any>;
  state: Record<string, any>;
  zipCode: string;
  documents: string[];
};

type CustomProps = {
  isActive: boolean;
};

export const HeirListComponent: React.FC<CustomProps> = ({ isActive }) => {
  const classes = styles();
  const [heirs, setHeirs] = useState([] as Partial<HeirCardProps>[]);
  const [addHeirModalVisible, setAddHeirModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null as any);
  const [reload, toggleReload] = useState(false);

  const getHeirsData = async (url?: string): Promise<any[]> => {
    let res;
    if (url) {
      res = await apiClientService.getFromServer(url);
    } else {
      res = await getHeirs();
    }
    return res?.data?.next
      ? res?.data.results.concat(await getHeirsData(res?.data?.next))
      : res?.data?.results;
  };

  useEffect(() => {
    getHeirsData().then((res) => {
      setHeirs(
        res.map((e: any) => ({
          id: e.id,
          description: '',
          relationship: e.relationship,
          type: e.heir_type,
          firstname: e.first_name,
          lastname: e.last_name,
          deceased: e.deceased,
          diedInState: e.died_state_data,
          diedInCounty: e.died_county_data,
          diedInCity: e.died_city_data,
          estateProbated: e.estate_probated,
          probateState: e.probate_state_data,
          probateCounty: e.probate_county_data,
          probateCity: e.probate_city_data,
          yearOfDeath: e.died_year,
          documents: [],

          age: e.age,
          includedInWill: e.included_in_will,
          streetAddress: e.potential_street,
          city: e.potential_city_data,
          county: e.potential_county_data,
          state: e.potential_state_data,
          zipCode: e.potential_zipcode,

          onEdit: () => {
            setSelectedData(e);
            setAddHeirModalVisible(true);
          },

          onDelete: () => {
            if (confirm(`Are you sure you want to remove ${e.first_name}?`)) {
              deleteHeir(e.id).then((res) => {
                if (res.status === 400) {
                  toast.error('Error encountered deleting heir.');
                } else {
                  toast.success('Heir deleted.');
                  toggleReload(!reload);
                  setAddHeirModalVisible(false);
                }
              });
            }
          },
        })),
      );
    });
  }, [isActive, reload]);

  const onAdd = (heirObj: any) => {
    const payload: any = {
      heir_type: heirObj.type,
      property: heirObj.property.value,
      relationship: heirObj.relationship.value,
      relationship_custom: '',
      first_name: heirObj.firstname,
      last_name: heirObj.lastname,
    };

    if (heirObj.type === 'Previous') {
      payload.deceased = heirObj.deceased;
      payload.died_city = heirObj.diedInCity?.value;
      payload.died_state = heirObj.diedInState?.value;
      payload.died_county = heirObj.diedInCounty?.value;
      payload.died_country = 234;
      payload.died_year = heirObj.yearOfDeath;
      payload.estate_probated = heirObj.estateProbated;
      payload.probate_city = heirObj.probateCity?.value;
      payload.probate_state = heirObj.probateState?.value;
      payload.probate_county = heirObj.probateCounty?.value;
      payload.probate_country = 234;
    } else {
      payload.age = heirObj.age;
      payload.included_in_will = heirObj.includedInWill;
      payload.potential_street = heirObj.streetAddress;
      payload.potential_state = heirObj.state.value;
      payload.potential_county = heirObj.county.value;
      payload.potential_city = heirObj.city.value;
      payload.potential_zipcode = heirObj.zipCode;
    }

    if (!heirObj.id) {
      addHeir(payload).then((res) => {
        if (res.status === 400) {
          let errMsg = '';
          Object.keys(res.data).forEach((e: any) => {
            if (e === 'age') errMsg = `Age: ${res.data[e].join(', ')}`;
            else errMsg = res.data[e].join(', ');
          });
          toast.error(errMsg || 'Error encountered updating heir.');
        } else {
          toast.success('Heir added.');
          toggleReload(!reload);
          setSelectedData(null);
          setAddHeirModalVisible(false);
        }
      });
    } else {
      updateHeir(heirObj.id, payload).then((res) => {
        if (res.status === 400) {
          let errMsg = '';
          Object.keys(res.data).forEach(
            (e: any) => (errMsg = res.data[e].join(', ')),
          );
          toast.error(errMsg || 'Error encountered updating heir.');
        } else {
          toast.success('Heir updated.');
          toggleReload(!reload);
          setSelectedData(null);
          setAddHeirModalVisible(false);
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <span className={classes.header}>Heirs</span>
        <Button
          text="Add"
          buttonStyle={classes.button}
          onClick={() => setAddHeirModalVisible(true)}
        />
      </div>

      <Pagination
        items={heirs}
        itemsPerPage={2}
        Element={PaginatedHeirCardComponent}
      />

      <AddHeirModal
        visible={addHeirModalVisible}
        onClose={() => {
          setSelectedData(null);
          setAddHeirModalVisible(false);
        }}
        onSubmit={(heirObj: any) => onAdd(heirObj)}
        data={selectedData}
      />
    </div>
  );
};

const styles = createUseStyles({
  container: {
    margin: 30,
    marginBottom: 0,
    width: '100%',
  },

  text: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  header: {
    fontSize: 30,
    fontWeight: '600',
    color: '#000',
  },

  button: {
    width: 73,
    height: 36,
  },
});
