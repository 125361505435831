import APIClient from './api-client.service';

export const getInquiries = () => {
  return APIClient.getFromServer('/inquiry/?limit=50');
};

export const getInquiryById = (id: string) => {
  return APIClient.getFromServer(`/inquiry/${id}/`);
};

export const createInquiry = (payload: Record<string, any>) => {
  return APIClient.postToServer('/inquiry/', payload);
};

export const patchInquiry = (id: string, payload: Record<string, any>) => {
  return APIClient.patchToServer(`/inquiry/${id}`, payload);
};

export const listInquiry = () => {
  return APIClient.getFromServer('/inquiry/');
};

export const getServiceCompanyInquiries = () => {
  return APIClient.getFromServer(
    '/inquiry_servicecompany/?expand=service_company,service_company.phones,service_company.city,service_company.state&limit=50',
  );
};
