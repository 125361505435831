import React from 'react';
import { createUseStyles } from 'react-jss';

type CustomProps = {
  name: string;
  image: string;
  position: string;
};

export const CardComponent: React.FC<CustomProps> = ({
  name,
  image,
  position,
}) => {
  const classes = styles();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 255 }}>
      <img src={image} className={classes.image} />
      <div style={{ marginTop: 20 }}>
        <span className={`${classes.text} ${classes.name}`}>{name}</span>
      </div>
      <div>
        <span className={`${classes.text} ${classes.position}`}>
          {position}
        </span>
      </div>
    </div>
  );
};

const styles = createUseStyles({
  image: {
    height: 80,
    width: 80,
    borderRadius: 100,
  },

  text: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    textAlign: 'left',
  },

  name: {
    fontSize: 18,
    fontWeight: 600,
    color: '#101828',
  },

  position: {
    fontSize: 16,
    color: '#336b8e',
  },
});
