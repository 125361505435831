import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import { Button } from '../../../components/button.component';
import { CheckBoxInput } from '../../../components/check-box.component';
import { Dropdown } from '../../../components/dropdown.component';
import { Modal } from '../../../components/modal.component';
import { TextInput } from '../../../components/text-input.component';
import { getProperties } from '../../../services/property.action';
import {
  getCities,
  getCounty,
  getState,
} from '../../../services/statics.action';
import { HeirType } from './heir-list.component';

type CustomProps = {
  visible: boolean;
  onClose: Function;
  onSubmit: Function;
  data: Record<string, any>;
};

export const AddHeirModal: React.FC<CustomProps> = ({
  visible,
  onClose,
  onSubmit,
  data,
}) => {
  const classes = styles();
  const [heir, setHeir] = useState({ type: 'Previous' } as any);
  const [properties, setProperties] = useState([] as any[]);
  const [state, setState] = useState([] as any[]);
  const [county, setCounty] = useState([] as any[]);
  const [diedInCounty, setDiedInCounty] = useState([] as any[]);
  const [probateCounty, setProbateCounty] = useState([] as any[]);
  const [cities, setCity] = useState([] as any[]);
  const [diedInCities, setDiedInCity] = useState([] as any[]);
  const [probateCities, setProbateCity] = useState([] as any[]);
  const [errors, setErrors] = useState({} as any);

  const onTypeChange = (type: HeirType) => {
    setHeir({ type });
    setErrors({});
  };

  const onAdd = () => {
    if (validated()) onSubmit(heir);
  };

  const validated = () => {
    const errors: any = {};

    if (heir.type === 'Previous') {
      if (!heir.firstname) errors.firstname = true;
      if (!heir.lastname) errors.lastname = true;
      if (!heir.relationship) errors.relationship = true;
      if (!heir.property) errors.property = true;
      if (heir.deceased) {
        if (!heir.diedInState) errors.diedInState = true;
        if (!heir.diedInCounty) errors.diedInCounty = true;
        if (!heir.diedInCity) errors.diedInCity = true;
        if (!heir.yearOfDeath) errors.yearOfDeath = true;
      }
      if (heir.estateProbated) {
        if (!heir.probateState) errors.probateState = true;
        if (!heir.probateCounty) errors.probateCounty = true;
        if (!heir.probateCity) errors.probateCity = true;
      }
    } else {
      if (!heir.firstname) errors.firstname = true;
      if (!heir.lastname) errors.lastname = true;
      if (!heir.relationship) errors.relationship = true;
      if (!heir.age) errors.age = true;
      if (heir.includedInWill === undefined) errors.includedInWill = true;
      if (!heir.streetAddress) errors.streetAddress = true;
      if (!heir.state) errors.state = true;
      if (!heir.county) errors.county = true;
      if (!heir.city) errors.city = true;
      if (!heir.zipCode) errors.zipCode = true;
    }

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error('Please check marked fields and correct your entries.');
      return;
    }

    return true;
  };

  const getCountyList = async (stateId: string) => {
    const res = await getCounty(stateId);
    return res.data.results.map((e: any) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
  };

  const getCityList = async (countyId: string) => {
    const res = await getCities(countyId);
    return res.data.results.map((e: any) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
  };

  useEffect(() => {
    getState().then((res: any) => {
      setState(
        res.data.results.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      );
    });

    getProperties().then((res: any) => {
      setProperties(
        res.data.results.map((e: any) => {
          return {
            value: e.id,
            label: e.alias,
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (visible) {
      if (data) {
        getState().then((res: any) => {
          setState(
            res.data.results.map((e: any) => {
              return {
                value: e.id,
                label: e.name,
              };
            }),
          );
        });

        if (data.type === 'Previous') {
          if (data.died_state_data)
            getCountyList(data.died_state_data.id).then((data) =>
              setDiedInCounty(data),
            );
          if (data.died_county_data)
            getCityList(data.died_county_data.id).then((data) =>
              setDiedInCity(data),
            );
          if (data.probate_state_data)
            getCountyList(data.probate_state_data.id).then((data) =>
              setProbateCounty(data),
            );
          if (data.probate_county_data)
            getCityList(data.probate_county_data.id).then((data) =>
              setProbateCity(data),
            );
        } else {
          if (data.potential_state_data)
            getCountyList(data.potential_state_data?.id).then((data) =>
              setCounty(data),
            );
          if (data.potential_county_data)
            getCityList(data.potential_county_data?.id).then((data) =>
              setCity(data),
            );
        }

        setHeir({
          id: data.id,
          type: data.heir_type,
          firstname: data.first_name,
          lastname: data.last_name,
          relationship: { label: data.relationship, value: data.relationship },
          property: {
            label: data.property?.alias,
            value: data.property?.id,
          },
          deceased: data.deceased,
          diedInState: {
            label: data.died_state_data?.name,
            value: data.died_state_data?.id,
          },
          diedInCounty: {
            label: data.died_county_data?.name,
            value: data.died_county_data?.id,
          },
          diedInCity: {
            label: data.died_city_data?.name,
            value: data.died_city_data?.id,
          },
          yearOfDeath: data.died_year,
          estateProbated: data.estate_probated,
          probateState: {
            label: data.probate_state_data?.name,
            value: data.probate_state_data?.id,
          },
          probateCounty: {
            label: data.probate_county_data?.name,
            value: data.probate_county_data?.id,
          },
          probateCity: {
            label: data.probate_city_data?.name,
            value: data.probate_city_data?.id,
          },
          age: data.age,
          includedInWill: data.included_in_will,
          streetAddress: data.potential_street,
          state: {
            label: data.potential_state_data?.name,
            value: data.potential_state_data?.id,
          },
          county: {
            label: data.potential_county_data?.name,
            value: data.potential_county_data?.id,
          },
          city: {
            label: data.potential_city_data?.name,
            value: data.potential_city_data?.id,
          },
          zipCode: data.potential_zipcode,
        });
      } else {
        setHeir({ type: 'Previous' });
      }
    } else {
      setErrors({});
      setHeir({ type: 'Previous' });
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={`${data ? 'Update' : 'Add'} Heir`}
      description="Helper description"
      icon="user-plus-02.svg"
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.container} style={{ marginRight: 20 }}>
            <div>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Type
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <CheckBoxInput
                  checked={heir.type === 'Previous'}
                  onChange={() => onTypeChange('Previous')}
                  inputStyle={classes.checkbox}
                  text="Previous"
                />
                <CheckBoxInput
                  checked={heir.type === 'Potential'}
                  onChange={() => onTypeChange('Potential')}
                  inputStyle={classes.checkbox}
                  text="Potential"
                />
              </div>
            </div>
            {heir.type === 'Previous' && (
              <>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Properties
                  </span>
                  <Dropdown
                    options={properties}
                    selectedOption={heir.property}
                    onChange={(value: any) =>
                      setHeir({ ...heir, property: value })
                    }
                    placeholder="Select Property..."
                    error={errors.property}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    First Name
                  </span>
                  <TextInput
                    placeholder="John"
                    value={heir.firstname || ''}
                    error={errors.firstname}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, firstname: value })
                    }
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Deceased
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CheckBoxInput
                      checked={heir.deceased === true}
                      onChange={() => setHeir({ ...heir, deceased: true })}
                      inputStyle={classes.checkbox}
                      text="Yes"
                    />
                    <CheckBoxInput
                      checked={heir.deceased === false}
                      onChange={() => setHeir({ ...heir, deceased: false })}
                      inputStyle={classes.checkbox}
                      text="No"
                    />
                  </div>
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Died in State
                  </span>
                  <Dropdown
                    options={state}
                    selectedOption={heir.diedInState}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        diedInState: value,
                        diedInCounty: null,
                        diedInCity: null,
                      });
                      getCountyList(value.value).then((data) =>
                        setDiedInCounty(data),
                      );
                      setDiedInCity([]);
                    }}
                    placeholder="State"
                    error={errors.diedInState}
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Died in County
                  </span>
                  <Dropdown
                    options={diedInCounty}
                    selectedOption={heir.diedInCounty}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        diedInCounty: value,
                        diedInCity: null,
                      });
                      getCityList(value.value).then((data) =>
                        setDiedInCity(data),
                      );
                    }}
                    placeholder="County"
                    error={errors.diedInCounty}
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Died in City
                  </span>
                  <Dropdown
                    options={diedInCities}
                    selectedOption={heir.diedInCity}
                    onChange={(value: any) =>
                      setHeir({ ...heir, diedInCity: value })
                    }
                    placeholder="City"
                    error={errors.diedInCity}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Year of death or range of years
                  </span>
                  <TextInput
                    placeholder="1975"
                    value={heir.yearOfDeath || ''}
                    error={errors.yearOfDeath}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, yearOfDeath: value })
                    }
                  />
                </div>
              </>
            )}
            {heir.type === 'Potential' && (
              <>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Relationship
                  </span>
                  <Dropdown
                    options={[
                      { label: 'Parent', value: 'Parent' },
                      { label: 'Grandparent', value: 'Grandparent' },
                      { label: 'Spouse', value: 'Spouse' },
                      { label: 'Sibling', value: 'Sibling' },
                      { label: 'Son', value: 'Son' },
                      { label: 'Grandson', value: 'Grandson' },
                      { label: 'Daughter', value: 'Daughter' },
                      { label: 'Granddaughter', value: 'Granddaughter' },
                      { label: 'Other', value: 'Other' },
                    ]}
                    selectedOption={heir.relationship}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        relationship: value,
                      });
                    }}
                    placeholder="Relationship"
                    error={errors.relationship}
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    First Name
                  </span>
                  <TextInput
                    placeholder="John"
                    value={heir.firstname || ''}
                    error={errors.firstname}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, firstname: value })
                    }
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Included in will
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CheckBoxInput
                      checked={heir.includedInWill === true}
                      onChange={() =>
                        setHeir({ ...heir, includedInWill: true })
                      }
                      inputStyle={classes.checkbox}
                      text="Yes"
                    />
                    <CheckBoxInput
                      checked={heir.includedInWill === false}
                      onChange={() =>
                        setHeir({ ...heir, includedInWill: false })
                      }
                      inputStyle={classes.checkbox}
                      text="No"
                    />
                  </div>
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Street Address
                  </span>
                  <TextInput
                    placeholder="Street address"
                    value={heir.streetAddress || ''}
                    error={errors.streetAddress}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, streetAddress: value })
                    }
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    State
                  </span>
                  <Dropdown
                    options={state}
                    selectedOption={heir.state}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        state: value,
                        county: null,
                        city: null,
                      });
                      getCountyList(value.value).then((data) =>
                        setCounty(data),
                      );
                      setCity([]);
                    }}
                    placeholder="State"
                    error={errors.state}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    County
                  </span>
                  <Dropdown
                    options={county}
                    selectedOption={heir.county}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        county: value,
                        city: null,
                      });
                      getCityList(value.value).then((data) => setCity(data));
                    }}
                    placeholder="County"
                    error={errors.county}
                  />
                </div>
              </>
            )}
          </div>
          <div className={classes.container}>
            {heir.type === 'Previous' && (
              <>
                <div style={{ marginTop: 82 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Relationship
                  </span>
                  <Dropdown
                    options={[
                      { label: 'Parent', value: 'Parent' },
                      { label: 'Grandparent', value: 'Grandparent' },
                      { label: 'Spouse', value: 'Spouse' },
                      { label: 'Sibling', value: 'Sibling' },
                      { label: 'Son', value: 'Son' },
                      { label: 'Grandson', value: 'Grandson' },
                      { label: 'Daughter', value: 'Daughter' },
                      { label: 'Granddaughter', value: 'Granddaughter' },
                      { label: 'Other', value: 'Other' },
                    ]}
                    selectedOption={heir.relationship}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        relationship: value,
                      });
                    }}
                    placeholder="Relationship"
                    error={errors.relationship}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Last Name
                  </span>
                  <TextInput
                    placeholder="Smith"
                    value={heir.lastname || ''}
                    error={errors.lastname}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, lastname: value })
                    }
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Estate probated
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CheckBoxInput
                      checked={heir.estateProbated === true}
                      onChange={() =>
                        setHeir({ ...heir, estateProbated: true })
                      }
                      inputStyle={classes.checkbox}
                      text="Yes"
                    />
                    <CheckBoxInput
                      checked={heir.estateProbated === false}
                      onChange={() =>
                        setHeir({ ...heir, estateProbated: false })
                      }
                      inputStyle={classes.checkbox}
                      text="No"
                    />
                  </div>
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Probate State
                  </span>
                  <Dropdown
                    options={state}
                    selectedOption={heir.probateState}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        probateState: value,
                        probateCounty: null,
                        probateCity: null,
                      });
                      getCountyList(value.value).then((data) =>
                        setProbateCounty(data),
                      );
                      setProbateCity([]);
                    }}
                    placeholder="State"
                    error={errors.probateState}
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Probate County
                  </span>
                  <Dropdown
                    options={probateCounty}
                    selectedOption={heir.probateCounty}
                    onChange={(value: any) => {
                      setHeir({
                        ...heir,
                        probateCounty: value,
                        probateCity: null,
                      });
                      getCityList(value.value).then((data) =>
                        setProbateCity(data),
                      );
                    }}
                    placeholder="County"
                    error={errors.probateCounty}
                  />
                </div>
                <div style={{ marginTop: 22 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Probate City
                  </span>
                  <Dropdown
                    options={probateCities}
                    selectedOption={heir.probateCity}
                    onChange={(value: any) =>
                      setHeir({ ...heir, probateCity: value })
                    }
                    placeholder="City"
                    error={errors.probateCity}
                  />
                </div>
              </>
            )}
            {heir.type === 'Potential' && (
              <>
                <div>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Properties
                  </span>
                  <Dropdown
                    options={properties}
                    selectedOption={heir.property}
                    onChange={(value: any) =>
                      setHeir({ ...heir, property: value })
                    }
                    placeholder="Select Property..."
                    error={errors.property}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Age
                  </span>
                  <TextInput
                    placeholder="80"
                    value={heir.age || ''}
                    error={errors.age}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, age: value })
                    }
                  />
                </div>
                <div style={{ marginTop: 18 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Last Name
                  </span>
                  <TextInput
                    placeholder="Smith"
                    value={heir.lastname || ''}
                    error={errors.lastname}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, lastname: value })
                    }
                  />
                </div>
                <div style={{ marginTop: 94 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    City
                  </span>
                  <Dropdown
                    options={cities}
                    selectedOption={heir.city}
                    onChange={(value: any) => setHeir({ ...heir, city: value })}
                    placeholder="City"
                    error={errors.city}
                  />
                </div>
                <div style={{ marginTop: 12 }}>
                  <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                    Zip Code
                  </span>
                  <TextInput
                    placeholder="Value"
                    value={heir.zipCode || ''}
                    error={errors.zipCode}
                    inputStyle={classes.fieldInput}
                    onTextChange={(value: any) =>
                      setHeir({ ...heir, zipCode: value })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: 25 }}>
            <Button
              text={data ? 'Update' : 'Add'}
              buttonStyle={classes.button}
              onClick={() => onAdd()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },

  textFont: {
    fontStretch: 'normal',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },

  fieldLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#344054',
    lineHeight: 2,
  },

  fieldInput: {
    width: 320,
  },

  button: {
    width: 740,
    height: 44,
  },

  checkbox: {
    marginTop: 14,
    marginRight: 20,
    color: '#344054',
    minWidth: 'max-content',
  },
});
