import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import { Button } from '../../../components/button.component';
import { Pagination } from '../../../components/pagination.component';
import apiClientService from '../../../services/api-client.service';
import {
  createProperty,
  deleteProperty,
  getProperties,
  updateProperty,
} from '../../../services/property.action';
import { AddPropertyModal } from './add-property-modal.component copy';
import { PaginatedPropertyCardComponent } from './paginated-property-card.component';

type Activity = {
  serviceCompanyName: string;
  activity: string;
};

export type Property = {
  id: string;
  name: string;
  description: string;
  alias: string;
  legalDescription: string;
  address: string;
  state: Record<string, any>;
  county: Record<string, any>;
  city?: Record<string, any>;
  minerals: any[];
  comments: string;
  documents: string[];
  activities: Activity[];
};

type CustomProps = {
  isActive: boolean;
};

export const PropertyListComponent: React.FC<CustomProps> = ({ isActive }) => {
  const classes = styles();
  const [properties, setProperties] = useState([] as Property[]);
  const [addPropertyVisible, setAddPropertyVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null as any);
  const [reload, toggleReload] = useState(false);
  const [processing, setProcessing] = useState(false);

  const fetchProperties = async (url?: string): Promise<any[]> => {
    let res;
    if (url) {
      res = await apiClientService.getFromServer(url);
    } else {
      res = await getProperties();
    }
    return res?.data?.next
      ? res?.data.results.concat(await fetchProperties(res?.data?.next))
      : res?.data?.results;
  };

  useEffect(() => {
    fetchProperties().then((data) => {
      setProperties(
        data.map((e: any) => ({
          id: e.id,
          name: e.name,
          description: e.description,
          alias: e.alias,
          legalDescription: e.legal_description,
          address: e.address,
          state: {
            value: e.state_data.id,
            label: e.state_data.name,
          },
          county: {
            value: e.county_data.id,
            label: e.county_data.name,
          },
          city: {
            value: e.city_data?.id,
            label: e.city_data?.name,
          },
          minerals: e.minerals_data,
          comments: e.comments,
          documents: e.documents_data,
          zipCode: e.zipcode,
          activities: [],
          // {
          //   serviceCompanyName: 'Service Company 01',
          //   activity: 'This is their activity',
          // }
          onEdit: () => {
            setSelectedData(e);
            setAddPropertyVisible(true);
          },
          onDelete: () => {
            if (confirm(`Are you sure you want to delete ${e.alias}?`)) {
              deleteProperty(e.id).then((res) => {
                if (res.status === 400) {
                  toast.error('Error encountered deleting property.');
                } else {
                  toast.success('Property deleted.');
                  toggleReload(!reload);
                  setAddPropertyVisible(false);
                }
              });
            }
          },
        })) || [],
      );
    });
  }, [isActive, reload]);

  const addProperty = (propertyObj: any) => {
    const payload = {
      name: propertyObj.name,
      description: propertyObj.description,
      alias: propertyObj.alias,
      legal_description: propertyObj.legalDescription,
      comments: propertyObj.comments,
      state: propertyObj.state.value,
      county: propertyObj.county.value,
      address: propertyObj.propertyAddress,
      city: propertyObj.city.value,
      zipcode: propertyObj.zipCode,
      minerals: propertyObj.minerals.map((e: any) => ({
        mineral_type: e.value,
        // must be updated not sure
        document_type: 'Conveyance Copy',
      })),
      imageFiles: propertyObj.documents.filter((e: any) => !e.uuid),
    };

    if (!propertyObj.id) {
      setProcessing(true);
      createProperty(payload)
        .then((res) => {
          if (res.status === 400) {
            toast.error('Error encountered adding property.');
          } else {
            setTimeout(() => {
              toast.success('Property added.');
              setSelectedData(null);
              setAddPropertyVisible(false);
              toggleReload(!reload);
            }, 1500);
          }
        })
        .finally(() => setTimeout(() => setProcessing(false), 1500));
    } else {
      setProcessing(true);
      updateProperty(propertyObj.id, payload)
        .then((res) => {
          if (res.status === 400) {
            toast.error('Error encountered updating property.');
          } else {
            toast.success('Property updated.');
            setTimeout(() => {
              setSelectedData(null);
              setAddPropertyVisible(false);
              toggleReload(!reload);
            }, 1500);
          }
        })
        .finally(() => setTimeout(() => setProcessing(false), 1500));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <span className={classes.header}>Property Overview</span>
        <Button
          text="Add"
          buttonStyle={classes.button}
          onClick={() => setAddPropertyVisible(true)}
        />
      </div>

      <Pagination
        items={properties}
        itemsPerPage={2}
        Element={PaginatedPropertyCardComponent}
      />

      <AddPropertyModal
        visible={addPropertyVisible}
        disabled={processing}
        onClose={() => {
          setSelectedData(null);
          setAddPropertyVisible(false);
        }}
        onSubmit={(propertyObj: any) => addProperty(propertyObj)}
        data={selectedData}
      />
    </div>
  );
};

const styles = createUseStyles({
  container: {
    margin: 30,
    marginBottom: 0,
    width: '100%',
  },

  text: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  header: {
    fontSize: 30,
    fontWeight: '600',
    color: '#000',
  },

  button: {
    width: 73,
    height: 36,
  },
});
