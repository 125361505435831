import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from '../../../components/button.component';
import { Pagination } from '../../../components/pagination.component';
import { Table } from '../../../components/table.component';
import apiClientService from '../../../services/api-client.service';
import { getInquiries, patchInquiry } from '../../../services/inquiry.action';
import { reload, selectIsReload } from '../../../store/slices/app.slice';
import { InquiryDeclineModal } from './inquiry-decline.component';
import { InquiryDetailsModal } from './inquiry-details.component';

export const PaginatedInquiriesTable: React.FC<{ currentItems: any[] }> = ({
  currentItems,
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const [modalVisible, setModalVisible] = useState(false);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [inquiryId, setInquiryId] = useState('');

  const acceptInquiry = (id: string) => {
    if (confirm(`Are you sure you want to accept this inquiry?`)) {
      patchInquiry(id, { status: 'Accepted' })
        .then((res) => {
          if (res.status === 200) {
            toast.success('Inquiry accepted.');
          } else {
            toast.error('Error encountered');
          }
        })
        .finally(() => dispatch(reload()));
    }
  };

  const inquiriesColumn = [
    {
      name: 'Date',
      selector: (row: Record<string, any>) => (
        <span>
          {new Date(row.initiated_at).toLocaleString('en-us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}
        </span>
      ),
      sortable: true,
      sortFunction: (a: Record<string, any>, b: Record<string, any>) =>
        a.date > b.date ? 1 : -1,
    },
    {
      name: 'Inquiry ID',
      selector: (row: Record<string, any>) => (
        <span style={{ fontWeight: 600 }}>{row.id}</span>
      ),
    },
    {
      name: 'Status',
      selector: (row: Record<string, any>) => {
        let color = '#000';
        if (row.status === 'Complete') color = '#31a672';
        else if (row.status === 'Declined') color = '#c11574';
        else if (row.status === 'In Progress') color = '#0086c9';
        else if (row.status === 'Pending') color = '#f47b35';
        else if (row.status === 'Needs Attention') color = '#c04b41';

        return (
          <div
            style={{
              borderRadius: 16,
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: color,
              padding: '3px 5px',
              color,
            }}
          >
            {row.status}
          </div>
        );
      },
    },
    // {
    //   name: 'Property Alias',
    //   selector: (row: Record<string, any>) => row.alias,
    // },
    {
      name: 'Actions',
      selector: (row: Record<string, any>) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            type="link_color"
            text="Accept"
            buttonStyle={classes.detailsButton}
            onClick={() => acceptInquiry(row.id)}
            disabled={row.status !== 'Pending'}
          />
          <Button
            type="link_color"
            text="Decline"
            destructive={true}
            buttonStyle={classes.detailsButton}
            onClick={() => {
              setInquiryId(row.id);
              setDeclineModalVisible(true);
            }}
            disabled={row.status !== 'Pending'}
          />
        </div>
      ),
    },
    {
      name: '',
      selector: (row: Record<string, any>) => (
        <Button
          type="link_color"
          text="Details"
          buttonStyle={classes.detailsButton}
          onClick={() => {
            setInquiryId(row.id);
            setModalVisible(true);
            row.reload();
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Table columns={inquiriesColumn} data={currentItems} selectable={true} />
      <InquiryDetailsModal
        visible={modalVisible}
        onClose={() => {
          setInquiryId('');
          setModalVisible(false);
        }}
        onSubmit={() => {}}
        inquiryId={inquiryId}
      />
      <InquiryDeclineModal
        visible={declineModalVisible}
        onClose={() => {
          setInquiryId('');
          setDeclineModalVisible(false);
        }}
        onSubmit={() => {}}
        inquiryId={inquiryId}
      />
    </>
  );
};

export const InquiriesComponent: React.FC = () => {
  const classes = styles();
  const [records, setRecords] = useState([] as Record<string, any>[]);
  const reload = useSelector(selectIsReload);

  const getInquiriesData = async (url?: string): Promise<any[]> => {
    let res;
    if (url) {
      res = await apiClientService.getFromServer(url);
    } else {
      res = await getInquiries();
    }
    return res?.data?.next
      ? res?.data.results.concat(await getInquiriesData(res?.data?.next))
      : res?.data?.results;
  };

  useEffect(() => {
    getInquiriesData().then((data) => {
      setRecords(
        data
          .filter((e) => e.category === 'Get Connected with Service')
          .map((e) => ({
            ...e,
            alias: '',
          })),
      );
    });
  }, [reload]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
          <div style={{ marginRight: 100 }}>
            <span className={classes.subheader}>Deals</span>
          </div>
          <Button
            type="link_gray"
            text=""
            iconLeading="download-cloud-01.svg"
            buttonStyle={classes.iconButton}
            onClick={() => {}}
          />
          <Button
            type="link_gray"
            text=""
            iconLeading="archive.svg"
            buttonStyle={classes.iconButton}
            onClick={() => {}}
          />
          <Button
            type="link_gray"
            text=""
            iconLeading="trash-01.svg"
            buttonStyle={classes.iconButton}
            onClick={() => {}}
          />
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <Pagination
          items={records}
          itemsPerPage={10}
          Element={PaginatedInquiriesTable}
        />
      </div>
    </div>
  );
};

const styles = createUseStyles({
  container: {
    margin: 30,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  headerContainer: {
    marginBottom: 25,
  },

  header: {
    fontSize: 30,
    fontWeight: '600',
    color: '#000',
  },

  subheader: {
    fontSize: 18,
    fontWeight: 600,
    color: '#101828',
    marginRight: 20,
  },

  text: {
    fontStyle: 'normal',
    textAlign: 'left',
  },

  fieldLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#344054',
    marginRight: 15,
    lineHeight: 2,
  },

  checkbox: {
    color: '#344054',
    minWidth: 'max-content',
    marginRight: 15,
    fontWeight: 500,
  },

  fieldInput: {
    width: 370,
  },

  button: {
    height: 44,
    width: 209,
  },

  iconButton: {
    height: 20,
    width: 20,
    marginRight: 20,
  },

  sellField: {
    width: 62,
  },

  detailsButton: {
    width: 47,
    height: 20,
    fontSize: 14,
    marginRight: 20,
  },
});
