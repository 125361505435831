import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import { Button } from '../../components/button.component';
import { ImageCarousel } from '../../components/carousel.component';
import { CheckBoxInput } from '../../components/check-box.component';
import { Footer } from '../../components/footer.component';
import { Header } from '../../components/header.component';
import { TextAreaInput } from '../../components/text-area.component';
import { TextInput } from '../../components/text-input.component';
import { createGuestMessage } from '../../services/guest-message.action';

interface CustomProps {
  navigation?: any;
}

const carouselImages = ['/Contact Us 1.jpg', '/Contact Us 2.jpg'];

export const ContactUsPage: React.FC<CustomProps> = ({ navigation }) => {
  const classes = styles();
  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [errors, setErrors] = useState({} as any);
  const [agreePolicy, setAgreePolicy] = useState(false);

  const inputHandler = (obj: Record<string, any>) => {
    Object.keys(obj).forEach((e: string) => delete errors[e]);
    setData({ ...data, ...obj });
  };

  const onSubmit = () => {
    if (!agreePolicy) {
      toast.error('Must agree to Privacy Policy.');
      return;
    }

    const errors: any = {};

    if (!data.firstname) errors.firstname = true;
    if (!data.lastname) errors.lastname = true;
    if (!data.email) errors.email = true;
    if (!data.phoneNumber) errors.phoneNumber = true;
    if (!data.message) errors.message = true;

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      toast.error('Please check marked fields and correct your entries.');
      return;
    }

    createGuestMessage({
      first_name: data.firstname,
      last_name: data.lastname,
      contact_email: data.email,
      contact_phone: data.phoneNumber,
      message: data.message,
    }).then((res) => {
      if (res.status === 400) {
        const errors: any = {};
        let errMsg = '';
        Object.keys(res.data).forEach((e: any) => {
          if (e === 'first_name') {
            errors.firstname = true;
          } else if (e === 'last_name') {
            errors.lastname = true;
          } else if (e === 'contact_email') {
            errors.email = true;
          } else if (e === 'contact_phone') {
            errors.phoneNumber = true;
          } else if (e === 'message') {
            errors.message = true;
          }
          errMsg = res.data[e].join(', ');
        });
        setErrors(errors);
        toast.error(
          errMsg || 'Please check marked fields and correct your entries.',
        );
      } else {
        toast.success('Message created.');
        window.location.reload();
      }
    });
  };

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.bodyContainer}>
        <div className={classes.bodyLeft}>
          <span className={`${classes.text} ${classes.header}`}>
            Get in touch
          </span>

          <div className={classes.nameFieldContainer}>
            <div>
              <div className={`${classes.text} ${classes.fieldLabel}`}>
                <span>First Name</span>
              </div>
              <TextInput
                placeholder="John"
                inputStyle={classes.nameField}
                value={data.firstname}
                error={errors.firstname}
                onTextChange={(value: string) =>
                  inputHandler({ firstname: value })
                }
              />
            </div>
            <div>
              <div className={`${classes.text} ${classes.fieldLabel}`}>
                <span>Last Name</span>
              </div>
              <TextInput
                placeholder="Smith"
                inputStyle={classes.nameField}
                value={data.lastname}
                error={errors.lastname}
                onTextChange={(value: string) =>
                  inputHandler({ lastname: value })
                }
              />
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <div className={`${classes.text} ${classes.fieldLabel}`}>
              <span>Email</span>
            </div>
            <TextInput
              placeholder="user@email.com"
              inputStyle={classes.textField}
              value={data.email}
              error={errors.email}
              onTextChange={(value: string) => inputHandler({ email: value })}
            />
          </div>

          <div style={{ marginTop: 24 }}>
            <div className={`${classes.text} ${classes.fieldLabel}`}>
              <span>Phone Number</span>
            </div>
            <TextInput
              placeholder="+1 208-528-8735"
              inputStyle={classes.textField}
              value={data.phoneNumber}
              error={errors.phoneNumber}
              onTextChange={(value: string) =>
                inputHandler({ phoneNumber: value })
              }
            />
          </div>

          <div style={{ marginTop: 24 }}>
            <div className={`${classes.text} ${classes.fieldLabel}`}>
              <span>Message</span>
            </div>
            <TextAreaInput
              placeholder="Leave us a message..."
              inputStyle={classes.textField}
              value={data.message}
              error={errors.message}
              rows={5}
              onTextChange={(value: string) => inputHandler({ message: value })}
            />
          </div>

          <div style={{ marginTop: 24 }}>
            <CheckBoxInput
              checked={agreePolicy}
              onChange={() => setAgreePolicy(!agreePolicy)}
              text="You agree to our privacy policy."
            />
          </div>

          <div style={{ marginTop: 32 }}>
            <Button
              text="Send Message"
              buttonStyle={classes.button}
              onClick={() => onSubmit()}
            />
          </div>
        </div>
        <div className={classes.bodyRight}>
          <ImageCarousel
            images={carouselImages}
            containerClassStyle={classes.carousel}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  bodyContainer: {
    margin: '0px 80px',
    display: 'flex',
    flexDirection: 'row',
  },

  bodyLeft: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 125,
  },

  text: {
    fontStyle: 'normal',
    textAlign: 'left',
  },

  header: {
    marginTop: 49,
    fontSize: 36,
    fontWeight: 600,
    color: '#101828',
  },

  supportingText: {
    marginTop: 12,
    fontSize: 20,
    fontWeight: 'normal',
    color: '#475467',
  },

  nameFieldContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  fieldLabel: {
    marginBottom: 6,
    fontSize: 14,
    fontWeight: 500,
    color: '#344054',
  },

  nameField: {
    width: 200,
  },

  textField: {
    width: 473,
  },

  button: {
    height: 48,
    width: 515,
    fontSize: 16,
  },

  bodyRight: {},

  footer: {
    marginLeft: 80,
    marginRight: 80,
    marginTop: 56,
    borderTop: ['solid', 1, '#d0d5dd'],
  },

  carousel: {
    width: 638,
    height: 780,
  },
});
