import React from 'react';
import { createUseStyles } from 'react-jss';
import { HeirCardComponent, HeirCardProps } from './heir-card.component';

type CustomProps = {
  currentItems: Partial<HeirCardProps>[];
};

export const PaginatedHeirCardComponent: React.FC<CustomProps> = ({
  currentItems,
}) => {
  const classes = styles();

  return (
    <div className={classes.headlines}>
      {currentItems.map((e, i) => (
        <div key={i} style={{ marginLeft: 16, marginRight: 16, marginTop: 48 }}>
          <HeirCardComponent
            id={e.id}
            description={e.description}
            relationship={e.relationship}
            type={e.type}
            firstname={e.firstname}
            lastname={e.lastname}
            deceased={e.deceased}
            diedInState={e.diedInState}
            diedInCounty={e.diedInCounty}
            diedInCity={e.diedInCity}
            estateProbated={e.estateProbated}
            probateState={e.probateState}
            probateCounty={e.probateCounty}
            probateCity={e.probateCity}
            yearOfDeath={e.yearOfDeath}
            documents={e.documents}
            age={e.age}
            includedInWill={e.includedInWill}
            streetAddress={e.streetAddress}
            city={e.city}
            state={e.state}
            zipCode={e.zipCode}
            onEdit={e.onEdit}
            onDelete={e.onDelete}
          />
        </div>
      ))}
    </div>
  );
};

const styles = createUseStyles({
  headlines: {
    display: 'inline-grid',
    gridTemplateColumns: '1fr 1fr',

    '@media (min-width: 1800px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },

  image: {
    height: 240,
    width: '100%',
  },
});
