import APIClient from './api-client.service';

export const getHeirs = () => {
  return APIClient.getFromServer('/heir/?expand=property');
};

export const addHeir = (payload: Record<string, any>) => {
  return APIClient.postToServer('/heir/?expand=property', payload);
};

export const updateHeir = (id: number, payload: Record<string, any>) => {
  return APIClient.putToServer(`/heir/${id}/?expand=property`, payload);
};

export const deleteHeir = (id: number) => {
  return APIClient.deleteFromServer(`/heir/${id}/`);
};
