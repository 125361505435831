import React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router';
import FreeHandIcon from '../../../assets/hand-drawn-arrow.svg';

type CustomProps = {
  onInquiry: Function;
};

export const FooterComponent: React.FC<CustomProps> = () => {
  const classes = styles();
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span className={`${classes.textFont} ${classes.bottomText}`}>
          Uncertain? We are here
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <span className={`${classes.textFont} ${classes.bottomText}`}>
            to help!
          </span>
          <span
            className={`${classes.textFont} ${classes.bottomText} ${classes.bottomTextStyle1}`}
            onClick={() => navigate('/contact-us')}
          >
            Ask Away!
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: 10,
          }}
        >
          <img src={FreeHandIcon} className={classes.arrow} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              marginTop: 30,
            }}
          >
            <span className={`${classes.textFont} ${classes.bottomTextStyle2}`}>
              Inquire
            </span>
            <span className={`${classes.textFont} ${classes.bottomTextStyle2}`}>
              about mineral
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = createUseStyles({
  textFont: {
    fontStretch: 'normal',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },

  bottomText: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
  },

  bottomTextStyle1: {
    fontWeight: 'bold',
    color: '#4c7d9c',
    marginLeft: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  bottomTextStyle2: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#62cdd9',
  },

  arrow: {
    height: 54,
  },
});
