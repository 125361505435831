import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { ImageCarousel } from '../../../components/carousel.component';
import { selectUser } from '../../../store/slices/app.slice';

const carouselImages = [
  'Company Overview.jpg',
  'Company Overview 1.jpg',
  'Company Overview 2.jpg',
];

export const ProfileComponent: React.FC = () => {
  const classes = styles();
  const user = useSelector(selectUser);

  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <ImageCarousel
          images={carouselImages}
          containerClassStyle={classes.image}
        />
        <div className={`${classes.text} ${classes.imageInfo}`}>
          <div
            style={{
              margin: '14px 24px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span style={{ fontWeight: 600 }}>{user.company_name}</span>
            <span>{user.user_data?.email}</span>
            <span>
              {formatPhoneNumber(user.phones ? user.phones[0]?.phone : '')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = createUseStyles({
  container: {
    margin: 30,
    display: 'flex',
    flexDirection: 'column',
    width: 592,
  },

  imageContainer: {
    position: 'relative',
    height: 371,
  },

  image: {
    width: '100%',
    height: 371,
  },

  text: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
  },

  imageInfo: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(24px)',
    '-webkitBackdropFilter': 'blur(24px)',
    color: '#fff',
    border: 'solid 1px rgba(255, 255, 255, 0.5)',
  },

  propertyDetailContainer: {
    marginTop: 21,
    display: 'flex',
    flexDirection: 'column',
  },

  propertyHeader: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'left',
    color: '#101828',
  },

  propertyDetail: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#475467',
    textAlign: 'justify',
  },
});
