import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '../../../components/button.component';
import { ImageCarousel } from '../../../components/carousel.component';

type CustomProps = {
  onContinue: Function;
};

const carouselImages = [
  '/Owner Reg 2 couple on porch.jpg',
  '/Owner reg 3 couple in mountains_.jpg',
];

export const VerificationComponent: React.FC<CustomProps> = ({
  onContinue,
}) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <ImageCarousel
          images={carouselImages}
          containerClassStyle={classes.carousel}
        />
      </div>
      <div className={classes.bodyContainer}>
        <span className={`${classes.text} ${classes.header}`}>
          Land Owner Registration
        </span>

        <span className={`${classes.text} ${classes.supportingText}`}>
          By registering as an owner with Subterra Data Services you will
          maximize the value of your minerals, manage court documents and
          heirships, and provide access to a directory of preferred industry
          vendors. Track, buy, and sell rights with ease.
        </span>

        <Button
          text="Continue"
          buttonStyle={classes.button}
          onClick={() => onContinue()}
        />
      </div>
    </div>
  );
};

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  imageContainer: {
    height: 380,
  },

  bodyContainer: {
    margin: '0px 80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 730,
  },

  text: {
    fontStyle: 'normal',
    textAlign: 'left',
  },

  header: {
    marginTop: 17,
    fontSize: 36,
    fontWeight: 600,
    color: '#101828',
  },

  supportingText: {
    marginTop: 6,
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#475467',
  },

  checkbox: {
    marginTop: 14,
    marginBottom: 25,
    color: '#344054',
  },

  button: {
    height: 41,
    width: 149,
    fontSize: 16,
    marginBottom: 150,
    marginTop: 30,
  },

  carousel: {
    height: 380,
    width: '100%',
  },
});
