import React from 'react';
import { createUseStyles } from 'react-jss';
import AnnotationQuestion from '../../../assets/annotation-question.svg';

type CustomProps = {
  title: string;
  description: string;
};

export const CardComponent: React.FC<CustomProps> = ({
  title,
  description,
}) => {
  const classes = styles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        backgroundColor: '#f9fafb',
      }}
    >
      <div
        style={{
          width: 48,
          height: 48,
          borderRadius: 10,
          backgroundColor: '#336b8e',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={AnnotationQuestion} style={{ height: 24, width: 24 }} />
      </div>
      <div style={{ marginTop: 35 }}>
        <span className={`${classes.text} ${classes.title}`}>{title}</span>
      </div>
      <div style={{ marginTop: 8, marginBottom: 67 }}>
        <span className={`${classes.text} ${classes.description}`}>
          {description}
        </span>
      </div>
    </div>
  );
};

const styles = createUseStyles({
  image: {
    height: 80,
    width: 80,
    borderRadius: 100,
  },

  text: {
    fontStyle: 'normal',
    fontStretch: 'normal',
    textAlign: 'left',
  },

  title: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000',
  },

  position: {
    fontSize: 16,
    color: '#336b8e',
  },

  description: {
    fontSize: 16,
    color: '#101828',
  },

  button: {
    width: 140,
    height: 24,
    fontSize: 16,
    fontWeight: 600,
  },
});
