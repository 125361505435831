import APIClient from './api-client.service';

export const getPresignedUrl = () => {
  return APIClient.postToServer('/picture/', {});
};

export const getDocumentPresignedUrl = (property) => {
  return APIClient.postToServer('/document/', { property });
};

export const uploadPicture = async (image) => {
  const uploadUrlData = await getPresignedUrl();
  if (!uploadUrlData?.data) return '';

  const formData = new FormData();
  Object.keys(uploadUrlData.data?.fields).forEach((e) => {
    if (e !== 'key') formData.append(e, uploadUrlData.data?.fields[e]);
  });
  formData.append('key', `${uploadUrlData.data.uuid}/${image.name}`);
  formData.append('file', image);
  await fetch(uploadUrlData.data.url, {
    method: 'POST',
    body: formData,
  }).catch((e) => console.log(e));

  return uploadUrlData.data.uuid;
};

export const uploadDocument = async (property, file) => {
  const uploadUrlData = await getDocumentPresignedUrl(property);
  if (!uploadUrlData?.data) return '';
  const formData = new FormData();
  Object.keys(uploadUrlData.data?.fields).forEach((e) => {
    if (e !== 'key') formData.append(e, uploadUrlData.data?.fields[e]);
  });
  formData.append('key', `${uploadUrlData.data.uuid}/${file.name}`);
  formData.append('file', file);
  await fetch(uploadUrlData.data.url, {
    method: 'POST',
    body: formData,
  }).catch((e) => console.log(e));

  return uploadUrlData.data.uuid;
};
