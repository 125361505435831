import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '../../../components/button.component';
import { CheckBoxInput } from '../../../components/check-box.component';
import { Modal } from '../../../components/modal.component';
import { Table } from '../../../components/table.component';
import { TextAreaInput } from '../../../components/text-area.component';
import { TextInput } from '../../../components/text-input.component';
import { getInquiryById } from '../../../services/inquiry.action';

type CustomProps = {
  visible: boolean;
  onClose: Function;
  onSubmit: Function;
  inquiryId: string;
};

export const InquiryDetailsModal: React.FC<CustomProps> = ({
  visible,
  onClose,
  onSubmit,
  inquiryId,
}) => {
  const classes = styles();
  const [inquiry, setInquiry] = useState({} as any);
  const [message, setMessage] = useState({} as any);

  const messagesColumn = [
    {
      name: 'Date',
      selector: (row: Record<string, any>) => row.date,
    },
    {
      name: 'Sender',
      selector: (row: Record<string, any>) => (
        <span style={{ fontWeight: 600 }}>{row.sender}</span>
      ),
    },
    {
      name: 'Message',
      selector: (row: Record<string, any>) => row.message,
    },
    {
      name: 'File Attachment',
      selector: (row: Record<string, any>) => (
        <span style={{ fontWeight: 600 }}>{row.attachment}</span>
      ),
    },
  ];

  useEffect(() => {
    if (visible) {
      getInquiryById(inquiryId).then((res) => {
        if (res.status === 200) {
          setInquiry({
            type: res.data.looking_for,
            category: res.data.category,
            mineralsOfInterest: res.data.minerals_of_interest_data?.map(
              (e: Record<string, any>) => e.title,
            ),
            looking_state_data: res.data.looking_state_data,
            properties: res.data.properties_data
              ?.map((e: Record<string, any>) => e.name)
              .join(', '),
            neededService: res.data.services_data
              ?.map((e: Record<string, any>) => e.service)
              .join(', '),
            comment: res.data.comment,
            messages: [],
          });
        } else {
          alert('Error fetching data');
          onClose();
        }
      });
    } else {
      setInquiry({});
    }
  }, [visible]);

  return (
    <>
      {Object.keys(inquiry).length > 0 && (
        <Modal
          visible={visible}
          onClose={onClose}
          title="Inquiry Details"
          description=""
          icon="file-check-02.svg"
        >
          <div className={classes.container}>
            {inquiry.category === 'Buy / Grow Portfolio' && (
              <>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 12,
                    }}
                  >
                    <span
                      className={`${classes.textFont} ${classes.fieldLabel}`}
                      style={{ marginRight: 23 }}
                    >
                      Type
                    </span>
                    <CheckBoxInput
                      checked={inquiry.type === 'Purchase'}
                      onChange={() => {}}
                      inputStyle={classes.checkbox}
                      text="Purchase"
                    />
                    <CheckBoxInput
                      checked={inquiry.type === 'Lease'}
                      onChange={() => {}}
                      inputStyle={classes.checkbox}
                      text="Sell or Lease"
                    />
                    <CheckBoxInput
                      checked={inquiry.type === 'Not Sure'}
                      onChange={() => {}}
                      inputStyle={classes.checkbox}
                      text="Not sure"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 12,
                  }}
                >
                  <div style={{ marginRight: 22 }}>
                    <span
                      className={`${classes.textFont} ${classes.fieldLabel}`}
                    >
                      County of interest
                    </span>
                    <TextInput
                      placeholder="Value"
                      value={inquiry.looking_state_data.name}
                      inputStyle={classes.fieldInput}
                      onTextChange={() => {}}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <span
                      className={`${classes.textFont} ${classes.fieldLabel}`}
                    >
                      Type of Mineral
                    </span>
                    <TextInput
                      placeholder="Value"
                      value={inquiry.mineralsOfInterest.join(', ')}
                      inputStyle={classes.fieldInput}
                      onTextChange={() => {}}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}
            {inquiry.category === 'Sell' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 12,
                  }}
                >
                  <div style={{ marginRight: 22 }}>
                    <span
                      className={`${classes.textFont} ${classes.fieldLabel}`}
                    >
                      Properties
                    </span>
                    <TextInput
                      placeholder="Value"
                      value={inquiry.properties}
                      inputStyle={classes.fieldInput}
                      onTextChange={() => {}}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}
            {inquiry.category === 'Get Connected with Service' && (
              <div style={{ marginBottom: 12 }}>
                <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                  What kind of service do you need help with today?
                </span>
                <TextInput
                  placeholder="Value"
                  value={inquiry.neededService}
                  inputStyle={classes.inputFull}
                  onTextChange={() => {}}
                  disabled={true}
                />
              </div>
            )}
            {['Get Connected with Service', 'Buy / Grow Portfolio'].includes(
              inquiry.category,
            ) && (
              <div style={{ marginBottom: 12 }}>
                <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                  Owner&rsquo;s comments
                </span>
                <TextAreaInput
                  placeholder="Value"
                  inputStyle={classes.inputFull}
                  value={inquiry.comment}
                  onTextChange={() => {}}
                  disabled={true}
                />
              </div>
            )}
            <div
              style={{
                marginBottom: 12,
                borderTop: 'solid',
                borderWidth: 0.5,
                borderColor: '#eaecf0',
              }}
            >
              <Table columns={messagesColumn} data={inquiry.messages} />
            </div>
            <div style={{ marginBottom: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                New Message
              </span>
              <TextInput
                placeholder="Recipient's Name"
                value={message.recipient}
                inputStyle={classes.inputFull}
                onTextChange={(value: any) =>
                  setMessage({ ...message, recipient: value })
                }
              />
            </div>
            <div style={{ marginBottom: 12 }}>
              <TextAreaInput
                placeholder="Message"
                inputStyle={classes.inputFull}
                value={message.message}
                onTextChange={(value: any) =>
                  setMessage({ ...message, message: value })
                }
              />
            </div>
            <div style={{ marginBottom: 12 }}>
              <Button
                type="link_gray"
                text="Select Attachment"
                iconLeading="paperclip.svg"
                buttonStyle={classes.attachmentButton}
                onClick={() => onSubmit()}
              />
            </div>
            <div style={{ marginBottom: 12 }}>
              <Button
                text="Send"
                buttonStyle={classes.button}
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
  },

  textFont: {
    fontStretch: 'normal',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },

  fieldLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#344054',
    lineHeight: 2,
  },

  fieldInput: {
    width: 320,
  },

  button: {
    width: 746,
    height: 44,
  },

  attachmentButton: {
    width: 165,
    height: 44,
    fontSize: 14,
  },

  inputFull: {
    width: 704,
  },

  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 14,
    borderRadius: 12,
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#eaecf0',
    padding: '16px 0px',
  },

  uploadIcon: {
    height: 40,
    width: 40,
    marginBottom: 10,
  },

  uploadButton: {
    width: 122,
    fontSize: 14,
  },

  uploadText: {
    fontSize: 14,
    color: '#475467',
  },

  checkbox: {
    marginRight: 22,
    color: '#344054',
    minWidth: 'max-content',
  },
});
