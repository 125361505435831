import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '../../../components/button.component';
import { Dropdown } from '../../../components/dropdown.component';
import { Modal } from '../../../components/modal.component';
import { TextAreaInput } from '../../../components/text-area.component';
import { TextInput } from '../../../components/text-input.component';
import {
  getCities,
  getCounty,
  getMineralTypes,
  getState,
} from '../../../services/statics.action';
import { toast } from 'react-toastify';
import { Tag } from '../../../components/tag.component';

type CustomProps = {
  visible: boolean;
  disabled: boolean;
  onClose: Function;
  onSubmit: Function;
  data: Record<string, any>;
};

export const AddPropertyModal: React.FC<CustomProps> = ({
  visible,
  disabled,
  onClose,
  onSubmit,
  data,
}) => {
  const classes = styles();
  const [state, setState] = useState([] as any[]);
  const [county, setCounty] = useState([] as any[]);
  const [cities, setCity] = useState([] as any[]);
  const [errors, setErrors] = useState({} as any);
  const [property, setProperty] = useState({} as any);
  const [minerals, setMinerals] = useState([] as any[]);

  const onAdd = () => {
    if (validated()) onSubmit(property);
  };

  const validated = () => {
    const errors: any = {};

    if (!property.name) errors.name = true;
    if (!property.alias) errors.alias = true;
    if (!property.propertyAddress) errors.propertyAddress = true;
    if (!property.state) errors.state = true;
    if (!property.county) errors.county = true;
    if (!property.description) errors.description = true;
    if (!property.legalDescription) errors.legalDescription = true;
    if (!property.city) errors.city = true;
    if (!property.zipCode) errors.zipCode = true;
    if (!property.comments) errors.comments = true;
    if (!property.minerals) errors.minerals = true;

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error('Please check marked fields and correct your entries.');
      return;
    }

    return true;
  };

  const getCountyList = async (stateId: string) => {
    const res = await getCounty(stateId);
    return res.data.results.map((e: any) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
  };

  const getCityList = async (countyId: string) => {
    const res = await getCities(countyId);
    return res.data.results.map((e: any) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
  };

  const handleFileChange = async (e: any) => {
    setProperty({
      ...property,
      documents: property.documents
        ? property.documents.concat(Array.from(e.target.files))
        : Array.from(e.target.files),
    });
  };

  const openFileUpload = () => {
    const input = document.getElementById('file-input');
    input?.click();
  };

  useEffect(() => {
    getState().then((res: any) => {
      setState(
        res.data.results.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      );
    });
    getMineralTypes().then((res: any) => {
      setMinerals(
        res.data.results.map((e: any) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (visible) {
      if (data) {
        getState().then((res: any) => {
          setState(
            res.data.results.map((e: any) => {
              return {
                value: e.id,
                label: e.name,
              };
            }),
          );
        });
        getCountyList(data.state).then((data) => setCounty(data));
        getCityList(data.county).then((data) => setCity(data));

        setProperty({
          id: data.id,
          name: data.name,
          alias: data.alias,
          propertyAddress: data.address,
          state: { label: data.state_data.name, value: data.state_data.id },
          county: { label: data.county_data.name, value: data.county_data.id },
          city: {
            label: data.city_data?.name,
            value: data.city_data?.id,
          },
          description: data.description,
          legalDescription: data.legal_description,
          zipCode: data.zipcode,
          comments: data.comments,
          documents: [],
          minerals: data.minerals_data.map((e: any) => ({
            label: e.mineral_type_data.title,
            value: e.mineral_type_data.id,
          })),
        });
      } else {
        setProperty({});
      }
    } else {
      setProperty({});
      setErrors({});
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={`${data ? 'Update' : 'Add'} Property?`}
      description="Helper description"
      icon="building-06.svg"
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.container} style={{ marginRight: 20 }}>
            <div>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Property Name*
              </span>
              <TextInput
                placeholder="Property Name"
                value={property.name || ''}
                error={errors.name}
                inputStyle={classes.fieldInput}
                onTextChange={(value: string) =>
                  setProperty({ ...property, name: value })
                }
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Property Alias*
              </span>
              <TextInput
                placeholder="Property Alias"
                value={property.alias || ''}
                error={errors.alias}
                inputStyle={classes.fieldInput}
                onTextChange={(value: string) =>
                  setProperty({ ...property, alias: value })
                }
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Property Address*
              </span>
              <TextInput
                placeholder="Property Address"
                value={property.propertyAddress || ''}
                error={errors.propertyAddress}
                inputStyle={classes.fieldInput}
                onTextChange={(value: string) =>
                  setProperty({ ...property, propertyAddress: value })
                }
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                State*
              </span>
              <Dropdown
                options={state}
                selectedOption={property.state}
                onChange={(value: any) => {
                  setProperty({
                    ...property,
                    state: value,
                    county: null,
                    city: null,
                  });
                  getCountyList(value.value).then((data) => setCounty(data));
                  setCity([]);
                }}
                placeholder="State"
                error={errors.state}
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                County*
              </span>
              <Dropdown
                options={county}
                selectedOption={property.county}
                onChange={(value: any) => {
                  setProperty({ ...property, county: value, city: null });
                  getCityList(value.value).then((data) => setCity(data));
                }}
                placeholder="County"
                error={errors.county}
              />
            </div>
          </div>
          <div className={classes.container}>
            <div>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Description*
              </span>
              <TextInput
                placeholder="Description"
                value={property.description || ''}
                error={errors.description}
                inputStyle={classes.fieldInput}
                onTextChange={(value: string) =>
                  setProperty({ ...property, description: value })
                }
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Legal Description*
              </span>
              <TextInput
                placeholder="Section Township Range"
                value={property.legalDescription || ''}
                error={errors.legalDescription}
                inputStyle={classes.fieldInput}
                onTextChange={(value: string) =>
                  setProperty({ ...property, legalDescription: value })
                }
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Minerals*
              </span>
              <Dropdown
                options={minerals}
                selectedOption={property.minerals}
                onChange={(value: any) =>
                  setProperty({ ...property, minerals: value })
                }
                placeholder="Mineral Type"
                error={errors.minerals}
                isMulti={true}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                City*
              </span>
              <Dropdown
                options={cities}
                selectedOption={property.city}
                onChange={(value: any) =>
                  setProperty({ ...property, city: value })
                }
                placeholder="City"
                error={errors.city}
              />
            </div>
            <div style={{ marginTop: 12 }}>
              <span className={`${classes.textFont} ${classes.fieldLabel}`}>
                Zip Code*
              </span>
              <TextInput
                placeholder="Zip Code"
                value={property.zipCode || ''}
                error={errors.zipCode}
                inputStyle={classes.fieldInput}
                onTextChange={(value: string) =>
                  setProperty({ ...property, zipCode: value })
                }
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: 12 }}>
            <span className={`${classes.textFont} ${classes.fieldLabel}`}>
              Comments*
            </span>
            <TextAreaInput
              placeholder="Comments"
              value={property.comments || ''}
              error={errors.comments}
              inputStyle={classes.input}
              onTextChange={(value: string) =>
                setProperty({ ...property, comments: value })
              }
            />
          </div>

          <div style={{ marginTop: 12 }}>
            <input
              style={{ display: 'none' }}
              id="file-input"
              type="file"
              onChange={handleFileChange}
              multiple
            />
            <Button
              type="secondary_gray"
              text="Add Document"
              iconLeading="upload-cloud-01.svg"
              buttonStyle={classes.uploadButton}
              onClick={() => openFileUpload()}
            />
            <div
              style={{
                maxWidth: 700,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 5,
              }}
            >
              {property.documents?.map((e: any, i: number) => (
                <Tag
                  key={i}
                  text={e.name ? e.name : e.file_path.split(/\//).pop()}
                  onClick={() => {
                    if (
                      confirm(
                        `Are you sure you want to remove ${
                          e.name ? e.name : e.file_path.split(/\//).pop()
                        }?`,
                      )
                    ) {
                      property.documents.splice(i, 1);
                      setProperty({
                        ...property,
                        documents: property.documents,
                      });
                    }
                  }}
                  tagStyle={classes.tag}
                />
              ))}
            </div>
          </div>

          <div style={{ marginTop: 12 }}>
            <Button
              disabled={disabled}
              text={data ? 'Update' : 'Add'}
              buttonStyle={classes.button}
              onClick={() => onAdd()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 360,
  },

  textFont: {
    fontStretch: 'normal',
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#000',
  },

  fieldLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#344054',
    lineHeight: 2,
  },

  fieldInput: {
    width: 320,
  },

  button: {
    width: 740,
    height: 44,
  },

  input: {
    width: 700,
  },

  uploadButton: {
    height: 25,
    width: 180,
    fontSize: 14,
  },

  tag: {
    marginRight: 5,
  },
});
