import APIClient from './api-client.service';
import { uploadPicture } from './image.service';

export const registerServiceCompany = async (payload: Record<string, any>) => {
  if (payload.imageFile) {
    payload.photo = await uploadPicture(payload.imageFile);
  }

  return APIClient.postToServer('/service_company/', payload);
};

export const registerLandOwner = (payload: Record<string, any>) => {
  return APIClient.postToServer('/owner/', payload);
};
